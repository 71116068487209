import React from "react";

const AppFeature = ({ icon: Icon, heading, content: Content }) => {
  return (
    <div className="flex-1 px-4 mb-8 max-w-lg mx-auto">
      {Icon && (
        <span className="bg-shamrock text-white rounded-full w-12 h-12 flex items-center justify-center drop-shadow-md shadow-pink-300 mb-4">
          <Icon className="fill-white w-6 h-6" />
        </span>
      )}

      <h5 className="font-bold mb-4">{heading}</h5>
      <p className="font-light">
        <Content />
      </p>
    </div>
  );
};

export default AppFeature;
