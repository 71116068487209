import React from "react";
import Modal from "./common/Modal";

const VideoModal = ({
  title = "Contact Us",
  open = false,
  onClose = function () {},
  videoEmbed: Embed,
}) => {
  return (
    <Modal open={open} onClose={onClose} size="4xl">
      <h2 className="font-semibold mb-4 text-lg">{title}</h2>
      <Embed />
    </Modal>
  );
};

export default VideoModal;
