import React, { useState } from "react";
import AppFeature from "../components/common/AppFeature";
import Button from "../components/common/Button";
import Gradient from "../components/common/Gradient";
import ContactModal from "../components/ContactModal";
import Layout from "../components/Layout";
import PhoneIcon from "../images/phone.inline.svg";
import IDIcon from "../images/id.inline.svg";
import PaymentsIcon from "../images/payments.inline.svg";
import TrendUpIcon from "../images/trendup.inline.svg";
import LockIcon from "../images/lock.inline.svg";
import LightBulbIcon from "../images/lightbulb.inline.svg";
import RobotIcon from "../images/robot.inline.svg";
import ChatIcon from "../images/chat.inline.svg";
import UtensilIcon from "../images/utensil.inline.svg";
import PlaneIcon from "../images/plane.inline.svg";
import GuestVideo from "../assets/kohost-guest-animation.mp4";
import VideoModal from "../components/VideoModal";
import { Helmet } from "react-helmet";
import Slideshow from "../components/common/Slideshow";

const YellowBlueGradient = ({ children }) => {
  return (
    <Gradient from="tertiary" to="secondary">
      {children}
    </Gradient>
  );
};

// convert AppFeature component to array of objects
const appFeatures = [
  {
    icon: PhoneIcon,
    heading: "Mobile check-in",
    content: () => (
      <span>
        Kohost automatically sends check-in links to all of your upcoming
        arrivals via{" "}
        <YellowBlueGradient>email or text message</YellowBlueGradient> allowing
        your guests to check-in without downloading an app.
      </span>
    ),
  },
  {
    icon: IDIcon,
    heading: "I.D. verification",
    content: () => (
      <span>
        Trust but verify. Kohost's ML models auto categorize and lift data from
        your guest's I.D.{" "}
        <YellowBlueGradient>Info and image</YellowBlueGradient> are sent to the
        PMS system so you can put a face to a reservation.
      </span>
    ),
  },
  {
    icon: PaymentsIcon,
    heading: "Payment capture",
    content: () => (
      <span>
        Kohost checks with your PMS during guest onboarding to make sure a
        payment is on file.{" "}
        <YellowBlueGradient>We tokenize the credit card</YellowBlueGradient> and
        send it to your PMS.
      </span>
    ),
  },
  {
    icon: TrendUpIcon,
    heading: "Upsell",
    content: () => (
      <span>
        No more awkward conversations at the front desk. Let the guest upgrade
        themselves! <YellowBlueGradient>Increase revpar</YellowBlueGradient>{" "}
        without lifting a finger.
      </span>
    ),
  },
  {
    icon: LockIcon,
    heading: "Digital key",
    content: () => (
      <span>
        Stop paying for key cards, replacing key card readers and making guests
        feel guilty for not returning them.
        <YellowBlueGradient>We handle the security</YellowBlueGradient> so you
        can focus on the guest.
      </span>
    ),
  },
  {
    icon: LightBulbIcon,
    heading: "Smart rooms",
    content: () => (
      <span>
        Save energy with lighting, media, climate, and shading automations.{" "}
        <YellowBlueGradient>Delight your guests</YellowBlueGradient> with
        welcome scenes and the ability to control their stay from their phone.
      </span>
    ),
  },
  {
    icon: RobotIcon,
    heading: "Automation",
    content: () => (
      <span>
        Let Kohost verify I.D., capture payments and hand digital keys to your
        guests.{" "}
        <YellowBlueGradient>Every single transaction</YellowBlueGradient> is
        handled on our operating system for hotels from check-in to check-out.
      </span>
    ),
  },
  {
    icon: ChatIcon,
    heading: "Guest communication",
    content: () => (
      <span>
        An AI powered digital concierge that communicates with your guests
        during{" "}
        <YellowBlueGradient>every point of their stay.</YellowBlueGradient>
      </span>
    ),
  },
  {
    icon: UtensilIcon,
    heading: "F&B",
    content: () => (
      <span>
        Integrate your POS systems to increase guest spend.{" "}
        <YellowBlueGradient>Drive ticket prices</YellowBlueGradient> with
        automated suggestions and add-on items.
      </span>
    ),
  },
  {
    icon: PlaneIcon,
    heading: "Mobile check-out",
    content: () => (
      <span>
        Turn over rooms faster by prompting guests to check-out instead of just
        leaving.{" "}
        <YellowBlueGradient>Capture sentiment with surveys</YellowBlueGradient>{" "}
        while their stay is still fresh in their mind.
      </span>
    ),
  },
];

const DemoVideo = () => {
  return (
    <iframe
      className="w-full aspect-video"
      src="https://www.youtube.com/embed/pE0AYfv31GU"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  );
};

const DigitalJourneyPage = () => {
  const [openContactModal, setOpenContactModal] = useState(false);
  const [openDemoModal, setOpenDemoModal] = useState(false);
  return (
    <>
      <Helmet>
        <title>Guest Digital Journey | Kohost</title>
      </Helmet>
      <Layout>
        <section className="max-w-7xl container mx-auto mt-16 mb-16 lg:mb-32 px-6">
          <div className="flex flex-col lg:flex-row">
            <div className="flex-1 mr-0 lg:mr-8 mb-8 lg:mb-0">
              <h1 className="text-6xl font-bold mb-8">
                <YellowBlueGradient>Fastest</YellowBlueGradient> way to onboard
                and upsell your guests.
              </h1>
              <p className="text-lg text-gray-600 mb-8">
                Built to save time and increase revenue by removing friction
                from your staff and guests' experience.
              </p>
              <p>
                <Button onClick={() => setOpenDemoModal(true)}>
                  Watch a demo →
                </Button>
              </p>
            </div>
            <div className="flex-1">
              <Slideshow />
            </div>
          </div>
        </section>
        <section className="max-w-full mx-auto my-16 flex items-center">
          <div className="w-full">
            <div className="mr-0 lg:mr-8 mb-16">
              <h2 className="font-bold text-5xl mb-8 text-center">
                Your hotel on{" "}
                <YellowBlueGradient>autopilot.</YellowBlueGradient>
              </h2>
              <p className="text-lg mb-8 lg:mb-0 text-center">
                Prioritize your guests with a first-of-its-kind guest experience
                platform.
              </p>
            </div>

            <div className="w-full">
              <video
                className="w-full mx-auto h-auto"
                muted
                autoPlay
                loop
                playsInline
                src={GuestVideo}
                type="video/mp4"
              />
            </div>
          </div>
        </section>

        <section className="max-w-3xl container mx-auto mt-16 mb-32 px-6">
          <h2 className="text-5xl font-bold mb-8 text-center">
            You've got the destination. <br />
            Let us help with the{" "}
            <YellowBlueGradient>Journey.</YellowBlueGradient>
          </h2>
        </section>

        <section className="max-w-6xl container mx-auto mt-16 mb-32 px-6">
          <div className="grid grid-cols-2 lg:grid-cols-5 grid-rows-2 gap-1">
            {appFeatures.map((feature, idx) => (
              <AppFeature key={idx + feature.heading} {...feature} />
            ))}
          </div>

          <p className="text-center">
            <Button onClick={() => setOpenContactModal(true)}>
              Get Started →
            </Button>
          </p>
        </section>
        <ContactModal
          open={openContactModal}
          onClose={() => setOpenContactModal(false)}
        />
        <VideoModal
          open={openDemoModal}
          title="Kohost Demo"
          videoEmbed={DemoVideo}
          onClose={() => setOpenDemoModal(false)}
        />
      </Layout>
    </>
  );
};

export default DigitalJourneyPage;
