import React from "react";
import Dashboard1 from "../../images/Dashboard+Laptop.jpg";
import Dashboard2 from "../../images/Dashboard+Laptop-2.jpg";
import Dashboard3 from "../../images/Dashboard+Laptop-3.jpg";
import Dashboard4 from "../../images/Dashboard+Laptop-4.jpg";
import classNames from "classnames";

const images = [Dashboard1, Dashboard2, Dashboard3, Dashboard4];

const delay = 3500;

function Slideshow() {
  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <div className="mx-auto overflow-hidden w-full">
      <div className="whitespace-nowrap transition-ease-out duration-1000 relative w-full h-[400px] min-h-full">
        {images.map((img, idx) => (
          <div
            key={idx}
            className={classNames(
              "transition ease-in-out duration-500 h-auto w-full inline-block absolute",
              {
                "opacity-0": idx !== index,
                "z-0": idx !== index,
                "opacity-100": idx === index,
                "z-10": idx === index,
              }
            )}
          >
            <img src={img} key={idx} alt="Dashboard" />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Slideshow;
